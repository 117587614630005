import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { motion } from "framer-motion";
import * as React from "react";
import { cn } from "~/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full font-sans font-normal tracking-tight transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default:
          "hover:bg-secondaryBg bg-invertedBg text-invertedText disabled:bg-secondaryBg disabled:text-secondaryText",
        inverted: "bg-primaryBg text-primaryText hover:bg-appBg disabled:bg-appBg disabled:text-secondaryText",
        outline:
          "text-primaryText hover:text-secondaryText disabled:border-primaryText disabled:text-secondaryText border border-current",
        secondary: "bg-appBg text-primaryText hover:bg-secondaryBg disabled:bg-tertiaryBg disabled:text-secondaryText",
        link: "text-primaryText hover:text-secondaryText underline underline-offset-4",
      },
      size: {
        small: "h-8 px-2.5 py-1.5 text-sm",
        default: "h-10 px-3 py-1.5 text-lg",
        large: "h-11 px-2.5 py-2 text-xl",
      },
      icon: {
        true: "aspect-square text-base",
      },
    },
    compoundVariants: [
      {
        icon: true,
        size: "small",
        className: "px-2",
      },
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, icon, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, icon, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

const MotionButton = motion(Button);

export { Button, buttonVariants, MotionButton };
